// DownloadErrorSnackbar.jsx
import React from 'react';
import { Snackbar, Alert } from '@mui/material';

const DownloadErrorSnackbar = ({ open, onClose }) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={onClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
        <Alert elevation={6} variant="filled" onClose={onClose} severity="error">
            ダウンロードに失敗しました。
        </Alert>
        </Snackbar>
    )
}

export default DownloadErrorSnackbar;