import React from 'react';
import { Chip } from '@mui/material';

const StatusCellRenderer = ({value}) => {
    // オンラインかオフラインかによってチップの色を設定
    const color = value === 'オンライン' ? 'success' : 'default';

    return <Chip label={value} color={color} />
}

export default StatusCellRenderer;