// AuthContext.jsx
import React, { createContext, useContext, useEffect, useState } from 'react';

// 認証情報を管理するためのコンテキストを作成
const AuthContext = createContext();

// 認証情報を提供するためのプロバイダーコンポーネント
export const AuthProvider = ({ children }) => {
    // 状態変数の定義
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        // ページがロードされる際にローカルストレージから認証情報を読み込む
        const storedAuth = localStorage.getItem('auth');
        if (storedAuth === 'true') {
            setIsAuthenticated(true);
        }
    }, []);

    const login = () => {
        // ログイン成功時に呼ばれる関数で、setIsAuthenticated(true)を実行
        setIsAuthenticated(true);
        localStorage.setItem('auth', 'true');
    };

    const logout = () => {
        // ログアウト時に呼ばれる関数で、setIsAuthenticated(false)を実行
        setIsAuthenticated(false);
        localStorage.removeItem('auth');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// 認証情報を利用するためのフック
export const useAuth = () => {
    const context = useContext(AuthContext);
    return context || {};
}