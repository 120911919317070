// UsageSumChart.jsx
import { Box, Typography, Stack, Divider} from '@mui/material';
import { BarChart, Bar, LabelList, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const UsageSumChart = ({monthryData, imeiWithTotal, timestampWithTotal, barColors}) => {
    // IMEIごとのStack Bar Chartのデータ作成
    const chartData = [];
    imeiWithTotal.forEach(imei => {
        const imeiData = monthryData[imei];
        imeiData.forEach(entry => {
            // 各imeiのデータをtimestampごとにデータとして追加
            const existingEntry = chartData.find(item => item.timestamp === entry.timestamp);
            if (existingEntry) {
                // 同じtimestampが既に存在する場合、usageSum_hを追加する
                existingEntry[imei] = entry.usageSum_h.toFixed(1);
            } else {
                // 新しいtimestampの場合、新しいオブジェクトを作成し、usageSum_hを追加する
                const newEntry = { timestamp: entry.timestamp };
                newEntry[imei] = entry.usageSum_h.toFixed(1);
                chartData.push(newEntry);
            }
        })
    })

    // timestampごとのusageSum_hの合計値を計算
    chartData.forEach(entry => {
        let total = 0;
        Object.keys(entry).forEach(key => {
            if (key !== 'timestamp') {
                total += parseFloat(entry[key]);
            }
        });
        entry.total = total.toFixed(1);
    });

    // 全てのtimestampにおけるUsageSum_kWhの合計値と平均値を計算
    let totalUsageSum = 0;
    chartData.forEach(entry => {
        totalUsageSum += parseFloat(entry.total);
    });
    const averageUsageSum = (totalUsageSum / imeiWithTotal.length).toFixed(1);
    totalUsageSum = totalUsageSum.toFixed(1);

    return (
        <Box>
            <Typography variant='h5' component='h2' style={{padding: '20px', textAlign: 'center'}}>
                使用時間
            </Typography>
            <Stack 
                direction="row" 
                maxWidth="xl"
                spacing={2}
                divider={<Divider orientation="vertical" flexItem />}
            >
                <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <Typography variant='body2'>
                        合計使用時間 <br />
                        {totalUsageSum} h
                    </Typography>
                </Box>
                <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <Typography variant='body2'>
                        1か月あたりの平均使用時間 <br />
                        {averageUsageSum} h
                    </Typography>
                </Box>
                <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <Typography variant='body2'>
                        デバイス数 <br />
                        {imeiWithTotal.length}
                    </Typography>
                </Box>
            </Stack>
            <ResponsiveContainer width="100%" height={500}>
                <BarChart width={800} height={500} data={chartData}>
                    {/* グリッド線 */}
                    <CartesianGrid strokeDasharray="3 3" />
                    {/* X軸 */}
                    <XAxis dataKey="timestamp" />
                    {/* Y軸 */}
                    <YAxis />
                    {/* ツールチップ */}
                    <Tooltip />
                    {/* 凡例 */}
                    <Legend />
                    {/* IMEIごとのバーを描画 */}
                    {imeiWithTotal.map((imei, index) => (
                        <Bar key={index} dataKey={imei} stackId="a" fill={barColors[index % barColors.length]} >
                            {/* 合計値を表示するバーの場合、合計値を表示する */}
                            {index === imeiWithTotal.length - 1 && <LabelList dataKey="total" position="top" />}
                        </Bar>
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </Box>
    )
}

export default UsageSumChart;