// ThemeProvider.jsx

import React from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';

// テーマを提供するコンポーネント
const ThemeProvider = ({ children }) => {

    // MUIのテーマを作成
    const theme = createTheme({
        palette: {
            // パレット設定
            mode: 'light',
                primary: {
                    main: '#1c2c56'
                }
        },
        typography: {
            fontFamily: [
                'Roboto',
                '"Noto Sans JP"', 
                '"Helvetica"',
                'Arial',
                'sans-serif',
        ].join(','),
    }
    });

    return (
        <MuiThemeProvider theme={theme}>
            {children}
        </MuiThemeProvider>
    )
};

export default ThemeProvider;
