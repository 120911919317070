// ConfirmResetPassword.jsx
import React, { useState } from 'react';
import { confirmResetPassword } from 'aws-amplify/auth';
import { TextField, Button, Card, CardContent, Typography, InputAdornment, IconButton, Popover, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check'

const ConfirmResetPassword = () => {
    // 状態変数の定義
    const [username, setUsername] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordRequirementsPopover, setPasswordRequirementsPopover] = useState(null);
    const [confirmPasswordLoading, setConfirmPasswordLoading] = useState(false);
    const navigate = useNavigate();

    // パスワードのリセットを処理する関数
    const handleConfirmResetPassword = async (event) => {
        event.preventDefault();
        try {
            setConfirmPasswordLoading(true);
            await confirmResetPassword({ username, confirmationCode, newPassword });
            console.log('Successfully reset password.');
            navigate('/login'); // パスワードリセット完了後にログイン画面に遷移
        } catch (error) {
            console.log(error);
        } finally {
            setConfirmPasswordLoading(false);
        }
    };

    // パスワード要件ポップオーバーを開く関数
    const handlePopoverOpen = (event) => {
        setPasswordRequirementsPopover(event.currentTarget);
    };

    // パスワード要件ポップオーバーを閉じる関数
    const handlePopoverClose = (event) => {
        setPasswordRequirementsPopover(null);
    };

    // パスワードの要件を満たしているかを確認する関数
    const isPasswordValid = () => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^()_+={}[\]:;<>,.?~\\s]).{8,}$/;
        return passwordRegex.test(newPassword);
    };

     // パスワードと確認用パスワードが一致しているかどうかを確認する関数
    const doPasswordsMatch = () => {
        return newPassword === confirmPassword;
    }
    
    return (
    <Card style={{ maxWidth: 400, margin: '20px auto' }}>
        <CardContent>
            <Typography variant='h5' component='h2' style={{ marginBottom: '20px' }}>
                パスワードリセット確認
            </Typography>
            {/* パスワードリセットフォーム */}
            <form onSubmit={handleConfirmResetPassword} noValidate autoComplete='off'>
                {/* ユーザー名の入力フィールド */}
                <TextField
                    label='ユーザー名'
                    variant='outlined'
                    fullWidth
                    margin='normal'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                {/* 確認コードの入力フィールド */}
                <TextField
                    label='確認コード'
                    variant='outlined'
                    fullWidth
                    margin='normal'
                    value={confirmationCode}
                    onChange={(e) => setConfirmationCode(e.target.value)}
                />
                {/* 新しいパスワードの入力フィールド */}
                <TextField
                    label='新しいパスワード'
                    variant='outlined'
                    fullWidth
                    margin='normal'
                    type='password'
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    InputProps={{
                        // パスワードの要件を表示するアイコン
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='パスワードの要件'
                                    onClick={handlePopoverOpen}
                                >
                                    <CheckIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                {/* パスワードの確認入力フィールド */}
                <TextField
                        label='パスワードの確認'
                        variant='outlined'
                        fullWidth
                        margin='normal'
                        type='password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {/* パスワードの要件ポップオーバー */}
                <Popover
                        open={Boolean(passwordRequirementsPopover)}
                        anchorEl={passwordRequirementsPopover}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                >
                    <Typography style={{ padding: '10px'}}>
                            パスワードの要件：
                            <br />
                            - 最小 8 文字
                            <br />
                            - 少なくとも 1 つの数字を含む
                            <br />
                            - 少なくとも 1 つの小文字を含む
                            <br />
                            - 少なくとも 1 つの大文字を含む
                            <br />
                            - 少なくとも 1 つの特殊文字、または先頭以外、末尾以外のスペース文字を含む
                    </Typography>
                </Popover>
                {/* パスワード更新ボタン */}
                <Button
                    type='submit'
                    color='primary'
                    variant='contained'
                    fullWidth
                    style={{ marginTop: '20px' }}
                    disabled={!isPasswordValid() || !doPasswordsMatch()}
                >
                    {confirmPasswordLoading ? 
                        (<CircularProgress size={24} color="inherit" />) : 
                        ('パスワードをリセット')
                    }
                </Button>
            </form>
        </CardContent>
    </Card>
    );
};

export default ConfirmResetPassword;
