// Dashboard.jsx
import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import fetchDashboardTableData from 'hooks/fetchDashboardTableData';
import DownloadButton from './DownloadButton/DownloadButton';
import ImeiSearch from './ImeiSearch';
import DashboardTable from './DashboardTable';
import SelectedImeiDisplay from './SelectedImeiDisplay';
import MaterialTabs from './DashboardTabs/DashboardTabs';

const Dashboard = () => {
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [selectedState, setSelectedState] = useState({ data: null, imei: null });
    const [searchQuery, setSearchQuery] = useState('');

    // コンポーネントがマウントされた時にデータを取得するためのuseEffectフック
    useEffect(() => {
        // データを取得する非同期関数
        const fetchData = async () => {
            try {
                const data = await fetchDashboardTableData();
                // 取得したデータを状態にセット
                setRowData(data);
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        }
        // fetchData関数を実行
        fetchData();

        // useEffect内で副作用を行った後のクリーンアップ関数
        // メモリーリークを防ぐために空の関数を返す
        return () => {};
    },[])

    // 行のフィルタリングを定義
    const filteredRows = useMemo(() => {
        if (!searchQuery) {
            return rowData;
        }
        return rowData.filter(row => row.imei.includes(searchQuery));
    }, [searchQuery, rowData]);

    // 行アクションを処理するコールバック
    const handleRowAction = useCallback(() => {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        if (selectedNodes.length > 0) {
            setSelectedState({ data: selectedNodes[0].data, imei: selectedNodes[0].data.imei });
        } else {
            const focusedCell = gridRef.current.api.getFocusedCell();
            if (focusedCell) {
                const rowData = gridRef.current.api.getRowNode(focusedCell.rowIndex).data;
                setSelectedState({ data: rowData, imei: rowData.imei });
            }
        }
    }, []);
    
    // 検索入力の変更を処理するコールバック
    const handleSearchInputChange = useCallback((event) => {
        setSearchQuery(event.target.value);
    }, []);

    // タブを閉じるコールバック
    const handleCloseTabs = useCallback(() => {
        setSelectedState({ data: null, imei: null });
    }, []);

    return (
        <Grid container spacing={2}>
            {/* ImeiSearch Component & Download Button Component */}
            <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <ImeiSearch value={searchQuery} onChange={handleSearchInputChange}/>
                    <DownloadButton gridRef={gridRef}/>
                </Box>
            </Grid>
            <Grid item xs={12}>
                {/* DashboardTable component */}
                <DashboardTable
                    gridRef={gridRef}
                    rowData={filteredRows}
                    handleRowAction={handleRowAction}
                />
            </Grid>
            <Grid item xs={12}>
                {/* Selected IMEI display Component */}
                <SelectedImeiDisplay selectedImei={selectedState.imei} />
            </Grid>
            <Grid item xs={12}>
                {/* Tabs Component */}
                {selectedState.imei && <MaterialTabs imei={selectedState.imei} onClose={handleCloseTabs} />}
            </Grid>
        </Grid>
    )
}

export default Dashboard;