// ImeiSearch.jsx
import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const ImeiSearch = ({ value, onChange }) => {
    return (
        <TextField
            variant="standard"
            placeholder="IMEIで検索"
            value={value}
            onChange={onChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                    <SearchIcon />
                    </InputAdornment>
                ),
                sx: {
                    height: '40px',
                    width: '300px',
                },
            }}
        />
    );
};

export default ImeiSearch;
