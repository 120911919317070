// Registration.jsx
import React, { useState } from 'react';
import postDeviceData from 'hooks/postDeviceData';
import { TextField, Button, Card, CardContent, Box, Container, Typography, Chip, InputAdornment, IconButton, Popover, CircularProgress, Snackbar, Alert } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const Registration = () => {
    // 入力フィールドの状態を管理するためのuseStateフック
    const [imei, setImei] = useState('');
    const [deviceName, setDeviceName] = useState('');
    const [location, setLocation] = useState('');
    const [isImeiValidating, setIsImeiValidating] = useState(false);
    const [imeiRequirementsPopover, setImeiRequirementsPopover] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    // フォーム送信時の処理
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            setIsImeiValidating(true); // IMEIを検証するフラグをセット
            await postDeviceData(imei, deviceName, location);
            // 送信後にフォームをリセット
            setImei('');
            setDeviceName('');
            setLocation('');
            setSnackbarSeverity('success');
            setSnackbarMessage('登録に成功しました');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error registering device:', error);
            setSnackbarSeverity('error');
            setSnackbarMessage('登録が失敗しました');
            setSnackbarOpen(true);
        } finally {
            setIsImeiValidating(false);
            setIsLoading(false);
        }
    };

    // IMEI要件を確認する関数
    const isImeiValid = (imei) => {
        const imeiRegex = /^\d{15}$/;
        return imeiRegex.test(imei);
    };

    // 他のTextFieldが空でないかをチェックする関数
    const isOtherFieldsFilled = () => {
        return !!deviceName && !!location;
    };

    // IMEI要件ポップオーバー
    const handleImeiPopoverOpen = (event) => {
        setImeiRequirementsPopover(event.currentTarget);
    };
    const handleImeiPopOverClose = (event) => {
        setImeiRequirementsPopover(null);
    }

    // Snackbarを閉じる関数
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container maxWidth="sm">
            <Card>
                <CardContent>
                    <Box display="flex" alignItems="center" marginBottom="20px">
                        <AddCircleOutlineIcon style={{marginRight: "8px"}}/>
                        <Typography variant='h5' component='h2'>
                            デバイス登録
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit} noValidate autoComplete='off'>
                        <Box display="flex" flexDirection="column" marginBottom="20px">
                            <Box display="flex" alignItems="center" marginBottom="10px">
                                <Typography>
                                    デバイス名
                                </Typography>
                                <Box marginLeft={1}>
                                    <Chip label="必須" size="small" />
                                </Box>
                            </Box>
                            <TextField
                                label="端末"
                                value={deviceName}
                                onChange={(e) => setDeviceName(e.target.value)}
                                fullWidth
                                variant='outlined'
                                InputLabelProps={{
                                    style: {
                                        opacity: 0.6, // ラベルの不透明度を設定
                                        ariaLabel: 'デバイス名'
                                    }
                                }}
                            />
                        </Box>
                        <Box display="flex" flexDirection="column" marginBottom="20px">
                            <Box display="flex" alignItems="center" marginBottom="10px">
                                <Typography>
                                    場所
                                </Typography>
                                <Box marginLeft={1}>
                                    <Chip label="必須" size="small"/>
                                </Box>
                            </Box>
                            <TextField
                                label="会議室"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                fullWidth
                                variant='outlined'
                                InputLabelProps={{
                                    style: {
                                        opacity: 0.6, // ラベルの不透明度を設定
                                        ariaLabel: '場所'
                                    }
                                }}
                            />
                        </Box>
                        <Box display="flex" flexDirection="column" marginBottom="20px">
                            <Box display="flex" alignItems="center" marginBottom="10px">
                                <Typography>
                                    IMEI
                                </Typography>
                                <Box marginLeft={1}>
                                    <Chip label="必須" size="small"/>
                                </Box>
                            </Box>
                            <TextField
                                label="123456789000000"
                                value={imei}
                                onChange={(e) => setImei(e.target.value)}
                                fullWidth
                                variant='outlined'
                                InputLabelProps={{
                                    style: {
                                        opacity: 0.6, // ラベルの不透明度を設定
                                        ariaLabel: 'IMEI'
                                    }
                                }}
                                InputProps={{
                                    // IMEIの要件を表示するアイコン
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='IMEIの要件'
                                                onClick={handleImeiPopoverOpen}
                                                size='small'
                                                color={isImeiValid(imei) ? 'success' : 'default'}
                                            >
                                                <CheckCircleOutlineIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {/* IMEIの要件ポップオーバー */}
                            <Popover
                                open={Boolean(imeiRequirementsPopover)}
                                anchorEl={imeiRequirementsPopover}
                                onClose={handleImeiPopOverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <Typography style={{ padding: '10px'}}>
                                IMEIの要件:
                                <br />
                                15桁の数字を入力
                            </Typography>
                            </Popover>
                        </Box>
                        <Box>
                            <Button 
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={!isImeiValid(imei) || !isOtherFieldsFilled()}
                            >
                                {isLoading ? <CircularProgress size={24} color="inherit" /> : '登録'}
                            </Button>
                        </Box>
                    </form>
                </CardContent>
            </Card>
            <Snackbar 
                open={snackbarOpen}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert elevation={6} variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default Registration;