// Logout.jsx
import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';

const Logout = () => {
    // react-router-domのnavigate関数を取得
    const navigate = useNavigate();

    // ログアウト処理を行うフック
    useEffect(() => {
        const handleLogout = async () => {
            try {
                await signOut(); // ログアウトを実行
                navigate('/login'); // ログアウト後にログイン画面にリダイレクト
            } catch(error) {
                console.log('error signing out', error);
            }
        }
        // ログアウト処理を実行
        handleLogout();
    }, [navigate]); // navigateが変更されるたびに再レンダリング

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <CircularProgress size={50} /> {/* ローディングアニメーションを表示 */}
        </div>
    )
}

export default Logout;