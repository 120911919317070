import CssBaseline from '@mui/material/CssBaseline'
import { Box, Container } from '@mui/material';
import ThemeProvider from 'providers/ThemeProvider';
import React, {useEffect, useState} from 'react';
import { Routes, Route, Navigate} from 'react-router-dom';
import { useAuth } from 'providers/AuthProvider';
import Header from 'components/Header';
import Dashboard from 'pages/Dashboard/Dashboard';
import Registration from 'pages/Registration/Registration';
import Monthry from 'pages/Monthry/Monthry';
import Login from 'pages/Login/Login';
import Logout from 'pages/Logout/Logout';
import UpdatePassword from 'pages/UpdatePassword/UpdatePassword';
import ResetPassword from 'pages/ResetPassword/ResetPassword';
import ConfirmResetPassword from 'pages/ConfirmResetPassword/ConfirmResetPassword';

function App() {
  const { isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(true); // ローディング状態の管理

  useEffect(() => {
    // 認証情報が取得されるまでローディング状態を制御
    if (isAuthenticated === undefined) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isAuthenticated]);

  if (loading) {
    return <div></div>
  }

  return (
    <ThemeProvider>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline>
            <Header />
            <Box
              component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
            >
              <Container maxWidth='100%' sx={{ mt: 10, mb: 4}}>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/update-password" element={<UpdatePassword />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/confirm-reset-password" element={<ConfirmResetPassword />} />
                  <Route path="/logout" element={<Logout />} />
                  {/* ログインした際にアクセス出来るパス */}
                  <Route
                    path="/"
                    element={isAuthenticated ? <Dashboard /> : <Login />}
                  />
                  <Route
                    path="/registration"
                    element={isAuthenticated ? <Registration /> : <Login />}
                  />
                  <Route
                    path="/monthry"
                    element={isAuthenticated ? <Monthry /> : <Login />}
                  />
                  {/* 他のルートをここに追加 */}

                  {/* 存在しないパスにアクセスされた場合にルートパスへリダイレクト */}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </Container>
            </Box>
        </CssBaseline>
      </Box>
    </ThemeProvider>
  );
}

export default App;
