// createDeviceData.jsx
import { generateClient } from '@aws-amplify/api';

const postDeviceData = async (imei, deviceName, location) => {
    try {
        // APIクライアントの作成
        const client = generateClient();

        // imeiデータを取得するクエリ文字列を作成
        const imeiDataQuery = `
            query GetAllDevices {
                getDeviceData {
                imei
                }
            }
        `;
        // GraphQL queryを実行してDynamoDBからimeiデータを取得
        const imeiDataResponse = await client.graphql({
            query: imeiDataQuery
        })
        const imeiData = imeiDataResponse.data.getDeviceData;

        // GraphQL createMutationのクエリ文字列を定義
        const createMutation = `
            mutation createDeviceData($input: DeviceDataInput!) {
                createDeviceData(input: $input) {
                    imei
                    deviceName
                    location
                }
            }
        `;

        // GraphQL createMutationのクエリ文字列を定義
        const updateMutation = `
            mutation updateDeviceData($input: DeviceDataInput!) {
                updateDeviceData(input: $input) {
                    imei
                    deviceName
                    location
                }
            }
        `;

        // GraphQL mutationの引数として使用するオブジェクト
        const input = {
            imei: imei,
            deviceName: deviceName,
            location: location
        };

        // IMEIが既存データに含まれているかをチェック
        const imeiExists = imeiData.some( device => device.imei === imei);

        // 適切なmutationを選択
        const mutation = imeiExists ? updateMutation : createMutation;

        // GraphQL mutationを実行してDynamoDBにデータを送信
        await client.graphql({
            query: mutation,
            variables: { input }
        });

    } catch (error) {
        console.error('Error registering device:', error);
    }
}

export default postDeviceData;