// DashboardTable.jsx
import React, { useMemo } from 'react';
import { Paper } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import './DashboardTable.css';
import useResizeGrid from 'hooks/useResizeGrid';
import StatusCellRenderer from './StatusCellRenderer';
import CustomNoRowsOverlay from 'providers/customNoRowsOverlay.jsx';
import { formatPowerValue } from 'providers/FunctionProvider.jsx';

const DashboardTable = ({ gridRef, rowData, handleRowAction }) => {

    // グリッドの列の幅を自動調整するカスタムフックを使用
    useResizeGrid(gridRef);
    const { onGridReady } = useResizeGrid(gridRef);

    // 列情報の定義
    const columnDefs = useMemo(() => [
        { checkboxSelection: true, width: 50},
        { headerName: "デバイス名", field: "deviceName"},
        { headerName: "場所", field: "location"},
        { headerName: "平均電力[W]", field: "powerTyp", valueFormatter: (params) => formatPowerValue(params.value)},
        { headerName: "IMEI", field: "imei" },
        { headerName: "状態", field: "status", cellRenderer: StatusCellRenderer},
        { headerName: "更新日時", field: "timestamp"},
    ].map(column => ({ ...column, headerClass: 'bold-header' })), []);
    
    // すべての列に対しての共通設定
    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
    }), []);

    // 行スタイルの定義
    const getRowStyle = useMemo(() => {
        return params => {
            return {
                background: params.node.rowIndex % 2 === 0 ? '#f2f2f2' : 'white'
            };
        };
    }, []);
    
    // Rowデータ無しの際(DB読み込み中)の表示
    const noRowsOverlayComponent = useMemo(() => { return CustomNoRowsOverlay }, []);
    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => 'Loading...',
        }
    }, []);

    return (
        <Paper
            sx={{
                p: 2,
                height: 400,
                width: '100%',
            }}
            className="ag-theme-material"
        >
            <AgGridReact
                ref={gridRef}
                onCellClicked={handleRowAction}
                onSelectionChanged={handleRowAction}
                onGridReady={onGridReady}
                rowData={rowData}
                columnDefs={columnDefs}
                rowSelection='single'
                animateRows={true}
                defaultColDef={defaultColDef}
                getRowStyle={getRowStyle}
                pagination={true}
                paginationPageSize={20} 
                noRowsOverlayComponent={noRowsOverlayComponent}
                noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            />
        </Paper>
    );
};

export default DashboardTable;
