// fetchDashboardTableData.jsx
import { generateClient } from '@aws-amplify/api';

// DynamoDBからdeviceDataとsensorDataを取得する関数を作成
const fetchDashboardTableData = async () => {
    try {
        // APIクライアントの作成
        const client = generateClient();
        
        // deviceDataを取得するクエリ文字列を作成
        const deviceDataQuery = `
            query GetAllDevices {
                getDeviceData {
                imei
                deviceName
                location
                }
            }
        `;
        // GraphQL queryを実行してDynamoDBからdeviceDataを取得
        const deviceDataResponse = await client.graphql({
            query: deviceDataQuery
        })
        const deviceData = deviceDataResponse.data.getDeviceData;

        // deviceDataのIMEIリストを取得
        const imeiList = deviceData.map(device => device.imei);

        // sesnsorDataを取得するクエリ文字列を作成
        const sensorDataQuery = `
            query GetSensorData($imei: String!) {
                getSensorData(imei: $imei) {
                    imei
                    timestamp
                    powerTyp
                    powerMin
                    powerMax
                    interval
                }
            }
        `

        // 各IMEIに対してsensorDataを取得
        const sensorDataResponses = await Promise.all(
            imeiList.map(imei => 
                client.graphql({
                    query: sensorDataQuery,
                    variables: { imei }
                })
                )
        )

        // sensorDataResponsesから実際のセンサーデータを取得
        const sensorData = sensorDataResponses.flatMap(response => {
            return response.data.getSensorData.map(sensor => {
                // 現在時刻
                const currentDate = new Date();
                // UTCの文字列からDateオブジェクトに変換
                const utcDate = new Date(sensor.timestamp);
                // JSTに変換
                const jstDate = new Date(utcDate.getTime() + (utcDate.getTimezoneOffset() * 60000) + (9 * 3600000));
                // timestampと現在時刻の差を計算
                const diffInSeconds = Math.abs(currentDate - jstDate) / 1000;
                // 90秒以内であればオンラインとしてStatusを設定
                const status = diffInSeconds <= 90 ? 'オンライン' : 'オフライン';
                // JSTの日付文字列に変換
                const jstDateString = jstDate.toLocaleString();
                // データを更新して返す
                return {...sensor, timestamp: jstDateString, status: status};
                
            })
        });

        // deviceDataとsensorDataをIMEIでマージ
        const mergedData = deviceData.map(device => {
            const sensorDatum = sensorData.find(sensor => sensor.imei === device.imei);
            return { ...device, ...sensorDatum };
        });

        return mergedData;

    } catch (error) {
        console.error('Error fetching data', error);
    }
}

export default fetchDashboardTableData;