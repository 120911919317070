// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
// AppSync, Cognito 連携設定
const config =  {
    API: {
        GraphQL: {
            endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
            region: process.env.REACT_APP_GRAPHQL_REGION || 'us-east-1', 
            defaultAuthMode: process.env.REACT_APP_GRAPHQL_AUTH_MODE || 'apiKey', 
            apiKey: process.env.REACT_APP_GRAPHQL_API_KEY,
        }
    },
    Auth: {
        Cognito: {
            username: 'true',
            userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
            userPoolClientId: process.env.REACT_APP_AUTH_USER_POOL_CLIENT_ID,
        }
    }
};

export default config;