// Header.jsx
import * as React from 'react';
import fetchUserInfo from 'hooks/fetchUserInfo';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';
import { useAuth } from 'providers/AuthProvider';
import { Box, Divider, Drawer, List, ListItem, AppBar, Toolbar, Button, ListItemIcon, ListItemText, Typography, ListItemButton} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LogoutIcon from '@mui/icons-material/Logout';

const Header = () => {
  // ユーザー情報を取得するstate
  const [userInfo, setUserInfo ] = useState(null);
  // Drawerの開閉状態を管理するstate
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate()
  const {logout} = useAuth();

  // ユーザー情報を取得
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await fetchUserInfo();
        setUserInfo(userData);
        console.log('User Info:', userInfo.username);
      } catch (error) {
        console.error('Error fetching user info: ', error)
      }
    };
    fetchUserData();
    
    // useEffect内でuseEffectを行った後のクリーンアップ関数
    return () => {};
  },[]);


  // ドロワーの開閉を行う関数
  const toggleDrawer = (open) => (event) => {
    // キーボードイベントを処理
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return; // TabキーとShiftキーでは何もしない
    }
    setDrawerOpen(open)
  }

  // ドロワー内のリストを作成する関数
  const drawerList = (
    <Box
      sx={{ width: 250}}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/')}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="ダッシュボード" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/registration')}>
              <ListItemIcon>
                <AddCircleOutlineIcon />
              </ListItemIcon>
              <ListItemText primary="デバイス登録" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/monthry')}>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary="月間使用状況" />
            </ListItemButton>
          </ListItem>
        </List>
    </Box>
  )

  const handleSignOut = async () => {
    try {
      await signOut(); // ログアウト処理を実行
      await logout(); // 認証情報のリセットを実行
      navigate('/logout') //ログイン完了後にリダイレクト
    } catch (error) {
      console.log('error signing out:', error);
    }
  }

  return (
    <AppBar position='absolute'>
      <Toolbar>
        {/* ドロワーメニュー */}
        <Button
          edge='start'
          color='inherit'
          aria-label='menu'
          sx={{ mr: 2 }}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </Button>
        <Drawer
          anchor='left'
          open={drawerOpen}
          onClose={toggleDrawer(false)}
        >
          {drawerList}
        </Drawer>
        {/* タイトル */}
        <Typography variant='h6' component='div' sx={{ flexGrow: 1}}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            電力監視システム
          </Link>
        </Typography>
        <Button
          edge='end'
          color='inherit'
          aria-label='sign-out'
          sx={{ml:2}}
          onClick={handleSignOut}
        >
          <LogoutIcon />
          <Typography variant='body1' sx={{ ml: 1 }}>ログアウト</Typography>
        </Button>
      </Toolbar>
    </AppBar>
  )
}

export default Header;