// Monthry.jsx
import React, { useEffect, useState } from 'react';
import { Container, Grid, Paper } from '@mui/material';
import fetchMonthryData from 'hooks/fetchMonthryData';
import PowerSumChart from './PowerSumChart';
import UsageSumChart from './UsageSumChart';

const Monthry = () => {
    const [monthryData, setMonthryData] = useState([]);

    // コンポーネントがマウントされた時にデータを取得するためのuseEffectフック
    useEffect(() => {
        // データを取得する非同期関数
        const fetchData = async () => {
            try {
                const data = await fetchMonthryData();

                // 取得したデータを状態にセット
                setMonthryData(data);
            } catch (error) {
                console.error('Error fetching monthry data:', error);
            }
        }

        // fetchData関数を実行
        fetchData();

        // 戻り値としてクリーンアップ関数を定義
        return () => {};
    }, [])

    // IMEIごとにデータをグループ化
    const dataByImei = {}
    monthryData.forEach(entry => {
        if (!dataByImei[entry.imei]) {
            dataByImei[entry.imei] = [];
        }
        dataByImei[entry.imei].push(entry);
    });

    // IMEIを格納した配列
    const imeiWithTotal = Object.keys(dataByImei);

    // timestampを格納した配列
    const timestampWithTotal = Object.keys(dataByImei);

    // カラーユニバーサルデザインに沿ったカラーパレットを定義
    const barColors = [
        '#42a5f5', // 青
        '#66bb6a', // 緑
        '#26c6da', // シアン
        '#ffa726', // 橙
        '#ffee58', // 黄
        '#ab47bc', // 紫
    ];

    return (
        <Container maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Paper>
                        <PowerSumChart monthryData={dataByImei} imeiWithTotal={imeiWithTotal} timestampWithTotal={timestampWithTotal} barColors={barColors} />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper>
                        <UsageSumChart monthryData={dataByImei} imeiWithTotal={imeiWithTotal} timestampWithTotal={timestampWithTotal} barColors={barColors} />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Monthry;