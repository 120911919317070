// FunctionProvider.jsx

// 消費電力の小数点表示フォーマット処理
export const formatPowerValue = (powerValue) => {
    if ((powerValue / 1000) >= 100) {
        return (powerValue / 1000).toFixed(1); // 100W以上の場合、小数点第一位まで表示
    } else if((powerValue / 1000) >= 10) {
        return (powerValue / 1000).toFixed(2); // 10W以上の場合、小数点第二位まで表示
    } else {
        return (powerValue / 1000).toFixed(3); // 小数点第三位まで表示
    }
};
