// Monitoring.jsx
import React from 'react';
import { Box } from '@mui/material';
import { ScatterChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Scatter  } from 'recharts';
import { formatPowerValue } from 'providers/FunctionProvider.jsx';

const Monitoring = ({ historyData }) => {
    // 取得したhistoryData配列を逆順にしたデータを作成
    const monitoringData = historyData.slice().reverse();

    // 最初のデータポイントの日付を取得
    const firstDate = monitoringData.length > 0 ? new Date(monitoringData[0].timestamp) : null;
    const isFirstDate = (date) => {
        return date.getDate() === firstDate.getDate() && date.getMonth() === firstDate.getMonth() && date.getFullYear() === firstDate.getFullYear();
    };

    // 最初の日付を取得
    const formatXAxis = (tickItem, index) => {
        const date = new Date(tickItem);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        if (data[index].isFirstDate) {
            // 最初のデータの場合は日付と時刻を表示
            return `${year}/${month}/${day} ${hours}:${String(minutes).padStart(2, '0')}`;
        } else {
            // それ以外の場合は時刻のみを表示
            return `${hours}:${String(minutes).padStart(2, '0')}`;
        }
    };

    // グラフデータの定義
    const data = monitoringData.map((entry, index) => ({
        date: entry.timestamp,
        xaxis_date: new Date(entry.timestamp).getTime(), // タイムスタンプをミリ秒に変換
        power: formatPowerValue(entry.powerTyp),
        threshold: '15.0',
        isFirstDate: index === 0 && isFirstDate(new Date(entry.timestamp))
    }));

    // powerの最大値を取得
    const max_unit = 20; // 20[W]の単位で丸める
    const maxPower = Math.ceil(Math.max(...data.map(entry => entry.power)) / max_unit) * max_unit;

    // デバッグ用：閾値のデータポイントをコンソールに出力
    // console.log("Threshold Data:", data);
    
    const powColor = '#1c2c56';
    const thColor = '#56461c';

    // マウスをオーバーしたときのスタイルを定義
    const tooltipStyle = {
        backgroundColor: 'white',
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '5px',
    };
    // マウスをオーバーしたときのカスタム表示
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            // マウスをオーバーしたときのポイントのデータ
            const pointData = payload[0].payload;
            
            // 表示内容を任意に変更する
            const formattedDate = new Date(pointData.date).toLocaleString();
            const time = `${formattedDate}`;
            const pow = `使用平均電力: ${pointData.power} W`;
            const th = `使用判定閾値: ${pointData.threshold} W`;
    
            return (
                <div className="custom-tooltip" style={tooltipStyle}>
                    {time}<br />
                    <span style={{ color: powColor }}>{pow}</span><br />
                    <span style={{ color: thColor }}>{th}</span>
                </div>
            );
        }
    
        return null;
    };

    return (
        <Box mt={2}>
            <ResponsiveContainer width="100%" height={300}>
                <ScatterChart
                    data={data}
                    margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                        dataKey="xaxis_date"
                        type="number"
                        domain = {['auto', 'auto']}
                        tickFormatter={(tick, index) => formatXAxis(tick, index)}
                    />
                    <YAxis 
                        domain={[0, maxPower]}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Scatter name="平均電力" dataKey="power" fill="#8e96ab" stroke={powColor} line={{stroke: powColor, strokeWidth: 2}} shape={{shape: 'circle', size: 30, strokeWidth: 1}} />
                    <Scatter name="閾値" dataKey="threshold" fill={thColor} stroke={thColor} line={{stroke: thColor, strokeWidth: 1}} shape={{shape: 'circle', size: 1, strokeWidth: 2}} />
                </ScatterChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default Monitoring;
