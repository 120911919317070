import { useEffect, useCallback } from 'react';

// グリッドの列の幅を自動調整するカスタムフック
const useResizeGrid = (gridRef) => {
    const resizeGrid = useCallback(() => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.sizeColumnsToFit();
        }
    }, [gridRef]);

    // 初回レンダリング時にグリッドの幅を調整する関数
    const onGridReady = useCallback(() => {
        resizeGrid();
    }, [resizeGrid]);

    useEffect(() => {
        window.addEventListener('resize', resizeGrid);

        return () => {
            window.removeEventListener('resize', resizeGrid);
        };
    }, [resizeGrid]);

    return { onGridReady, resizeGrid};
};

export default useResizeGrid;
