// fetchMonthryData.jsx
import { generateClient } from '@aws-amplify/api';

// DynamoDBから月間使用状況を取得するカスタムフックを作成
const fetchMonthryData = async () => {
    try {
        // APIクライアントの作成
        const client = generateClient();

        // monthryDataを取得するクエリ文字列を作成
        const monthryDataQuery = `
            query GetMonthryData {
                getMonthryData {
                imei
                timestamp
                month
                powerSum_kWh
                usageSum_h
                year
                }
            }
        `;

        // GraphQL Queryを実行してDynamoDBからmonthryDataを取得
        const monthryDataResponse = await client.graphql({
            query: monthryDataQuery
        });

        const monthryData = monthryDataResponse.data.getMonthryData;

        return monthryData;

    } catch (error) {
        console.error('Error fetching data', error);
    }
}

export default fetchMonthryData;