// UpdatePassword.jsx
import React, { useState } from 'react';
import { confirmSignIn, signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Card, CardContent, Typography, InputAdornment, IconButton, Popover, CircularProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check'

const UpdatePassword = () => {
    // 状態変数の定義
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordRequirementsPopover, setPasswordRequirementsPopover] = useState(null);
    const [isPasswordValidating, setIsPasswordValidating] = useState(false);
    const navigate = useNavigate();

    // パスワードの更新を処理する関数
    const handleUpdatePassword = async (event) => {
        event.preventDefault();
        try {
            setIsPasswordValidating(true); // パスワード検証中を示すフラグをセット
            const result = await confirmSignIn({ challengeResponse: newPassword }); // 新しいパスワードを提供して confirmSignIn メソッドを呼び出す
            if (result.nextStep.signInStep === "DONE") {
                await signOut(); // confirmSignInメソッドが成功時にsignOut関数を実行
                navigate('/login'); // ログアウト後にログインページにリダイレクト
            }
        } catch (error) {
            console.log('パスワードの更新中にエラーが発生しました:', error);
        } finally {
            setIsPasswordValidating(false);  // 検証が完了したらフラグをリセット
        }
    };

    // パスワード要件ポップオーバーを開く関数
    const handlePopoverOpen = (event) => {
        setPasswordRequirementsPopover(event.currentTarget);
    };

    // パスワード要件ポップオーバーを閉じる関数
    const handlePopoverClose = (event) => {
        setPasswordRequirementsPopover(null);
    };

    // パスワードの要件を満たしているかを確認する関数
    const isPasswordValid = () => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^()_+={}[\]:;<>,.?~\\s]).{8,}$/;
        return passwordRegex.test(newPassword);
    };

    // パスワードと確認用パスワードが一致しているかどうかを確認する関数
    const doPasswordsMatch = () => {
        return newPassword === confirmPassword;
    }

    return (
        <Card style={{ maxWidth: 400, margin: '20px auto' }}>
            <CardContent>
                <Typography variant='h5' component='h2' style={{ marginBottom: '20px' }}>
                    パスワード更新
                </Typography>
                {/* パスワード更新フォーム */}
                <form onSubmit={handleUpdatePassword} noValidate autoComplete="off">
                    {/* 新しいパスワードの入力フィールド */}
                    <TextField
                        label='新しいパスワード'
                        variant='outlined'
                        fullWidth
                        margin='normal'
                        type='password'
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        InputProps={{
                            // パスワードの要件を表示するアイコン
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='パスワードの要件'
                                        onClick={handlePopoverOpen}
                                    >
                                        <CheckIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    {/* パスワードの確認入力フィールド */}
                    <TextField
                        label='パスワードの確認'
                        variant='outlined'
                        fullWidth
                        margin='normal'
                        type='password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {/* パスワードの要件ポップオーバー */}
                    <Popover
                        open={Boolean(passwordRequirementsPopover)}
                        anchorEl={passwordRequirementsPopover}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Typography style={{ padding: '10px'}}>
                            パスワードの要件：
                            <br />
                            - 最小 8 文字
                            <br />
                            - 少なくとも 1 つの数字を含む
                            <br />
                            - 少なくとも 1 つの小文字を含む
                            <br />
                            - 少なくとも 1 つの大文字を含む
                            <br />
                            - 少なくとも 1 つの特殊文字、または先頭以外、末尾以外のスペース文字を含む
                        </Typography>
                    </Popover>
                    {/* パスワード更新ボタン */}
                    <Button
                        type='submit'
                        color='primary'
                        variant='contained'
                        fullWidth
                        style={{ marginTop: '20px' }}
                        disabled={!isPasswordValid() || !doPasswordsMatch()}
                    >
                        {isPasswordValidating ? (
                            <CircularProgress size={24} color="inherit" />  // 検証中はローディングインジケーターを表示
                        ) : (
                            '更新する'
                        )}
                    </Button>
                </form>
            </CardContent>
        </Card>
    );
};

export default UpdatePassword;
