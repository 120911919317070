// DownloadSuccessSnackbar.jsx
import React from "react";
import { Snackbar, Alert } from "@mui/material";

const DownloadSuccessSnackbar = ({ open, onClose }) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={onClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <Alert elevation={6} variant="filled" onClose={onClose} severity="success">
                ダウンロードが完了しました。
            </Alert>
        </Snackbar>
    )
}

export default DownloadSuccessSnackbar;