// SelectedImeiDisplay.jsx
import React from 'react';
import {Paper, Typography} from '@mui/material';

const SelectedImeiDisplay = ({ selectedImei }) => {
    return (
        <Paper
            sx={{
                p:2,
                height: '100%',
                width: '100%',
            }}
        >
            <Typography align="left" sx={{color: '#1c2c56', fontWeight: 'bold'}}>
                {selectedImei ? `IMEI: ${selectedImei}` : 'IMEIを選択'}
            </Typography>
        </Paper>
    )
}

export default SelectedImeiDisplay;