// ResetPassword.jsx
import React, { useState } from 'react';
import { resetPassword} from 'aws-amplify/auth';
import { TextField, Button, Card, CardContent, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    // 状態変数の定義
    const [username, setUsername] = useState('');
    const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
    const navigate = useNavigate();

    // パスワードリセット処理を行う関数
    const handleResetPassword = async () => {
        try {
            setResetPasswordLoading(true); // パスワードリセット処理中フラグをセット
            const output = await resetPassword({ username }); // ユーザー名を使用してパスワードリセットを実行
            handleResetPasswordNextSteps(output); // パスワードリセットの次の手順を処理
        } catch (error) {
            console.log(error);
        } finally {
            setResetPasswordLoading(false); // パスワードリセット処理中フラグをリセット
        }
    };

    // パスワードリセットの次の手順を処理する関数
    const handleResetPasswordNextSteps = (output) => {
        const { nextStep } = output; // 出力から次の手順を取得
        switch (nextStep.resetPasswordStep) {
            case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
                navigate('/confirm-reset-password'); // 確認コード入力画面にリダイレクト
                break;
            case 'DONE':
                console.log('Successfully reset password.');
                break;
            default:
                console.log('Unexpected step in password reset process.');
        }
    };

    return (
    <Card style={{ maxWidth: 400, margin: '20px auto' }}>
        <CardContent>
            {/* パスワードリセットフォーム */}
            <Typography variant='h5' component='h2' style={{ marginBottom: '20px' }}>
                パスワードリセット
            </Typography>
            {/* ユーザー名の入力フィールド */}
            <TextField
                label='ユーザー名'
                variant='outlined'
                fullWidth
                margin='normal'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            {/* パスワードリセットボタン */}
            <Button
                type='button'
                color='primary'
                variant='contained'
                fullWidth
                style={{ marginTop: '20px' }}
                disabled={resetPasswordLoading}
                onClick={handleResetPassword}
            >
                {resetPasswordLoading ? (
                <CircularProgress size={24} color="inherit" />
                ) : (
                'リセットコードを送信'
                )}
            </Button>
        </CardContent>
    </Card>
    );
};

export default ResetPassword;
