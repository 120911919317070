// fetchDashboardTabsData.jsx
import { generateClient } from '@aws-amplify/api';

// DynamoDBからsensorDataの履歴を取得する関数を作成
const fetchHistoryData = async (imei) => {
    try {
        if (!imei) {
            return null; // IMEIが未定義の場合はクエリを実行せず、nullを返す
        }
        // APIクライアントの作成
        const client = generateClient()

        // センサーデータの履歴を100件取得するクエリ
        const historyQuery = `
            query GetSensorHistory($imei: String!) {
                getSensorHistory(imei: $imei) {
                imei
                timestamp
                powerTyp
                powerMin
                powerMax
                interval
                }
            }
        `;

        // GraphQL queryを実行して履歴データを取得
        const historyResponse = await client.graphql({
            query: historyQuery,
            variables: { imei }
        });

        // historyResponseから実際のセンサーデータを取得
        const historyDataWithJST = historyResponse.data.getSensorHistory.map(data => {
            const utcDate = new Date(data.timestamp);
            const jstDate = new Date(utcDate.getTime() + (utcDate.getTimezoneOffset() * 60000) + (9 * 3600000));
            const jstDateString = jstDate.toLocaleString();
            return { ...data, timestamp: jstDateString };
        });

        return historyDataWithJST; // 履歴データを返す

    } catch (error) {
        console.error('Error fetching history data:', error);
        throw error; // エラーが発生した場合はエラーをスローする
    }
};

export default fetchHistoryData;