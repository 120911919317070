// fetchUserInfo.jsx
import { getCurrentUser } from 'aws-amplify/auth';

// ユーザーのアカウント情報を取得する関数を作成
const fetchUserInfo = async () => {
    try {
        const { username, userId, signInDetails } = await getCurrentUser();
        return { username, userId, signInDetails }
    } catch (error) {
        console.log('Error fetching user info: ', error)
        throw error;
    }
}

export default fetchUserInfo;