// DashboardTabss.jsx
import React, { useEffect, useState } from 'react';
import fetchHistoryData from 'hooks/fetchHistoryData';
import History from './History';
import Monitoring from './Monitoring';
import { Typography, Paper, Tabs, Tab, CircularProgress } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';

const DashboardTabss = ({ imei, onClose }) => {
    const [activeTab, setActiveTab] = useState('monitoring');
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(false); // リロードボタンのローディング状態

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    }

    // リロードボタンを押下した際の処理
    const onReload = async () => {
        setLoading(true);
        const currentActiveTab = activeTab; // 現在のactiveTabを保持
        await fetchTabsData(imei);
        setActiveTab(currentActiveTab); // リロード後に再度activeTabをセット
        setLoading(false); 
    };

    // IMEIの変更があった際に、DynamoDBからsensorDataの履歴を取得する
    useEffect(() => {
        fetchTabsData(imei);
    }, [imei]);

    // 履歴データを取得する
    const fetchTabsData = async (imei) => {
        try {
            const data = await fetchHistoryData(imei);
            if (data) {
                setHistoryData(data);
            }
        } catch (error) {
            console.error('Error fetching history data', error)
        } finally {
            setLoading(false) // ローディング状態のリセット
        }
    }

    return (
        <Paper
            sx={{
                p:2,
                height: '100%',
                width: '100%',
            }}
        >
        <Typography variant="h6" gutterBottom>
            最新の電力値
        </Typography>
            <Tabs 
                value={activeTab} 
                onChange={handleChange} 
                aria-label="table tabs"
            >
                <Tab label="モニタリング" value="monitoring" />
                <Tab label="履歴" value="history" />
                <Tab 
                    icon={loading ? <CircularProgress size={24} /> : <RefreshIcon />}
                    value='reload'
                    onClick={onReload}
                />
                <Tab 
                    icon={<CloseIcon />}
                    value='close'
                    onClick={onClose}
                />
                {/* 他のタブもここに追加 */}
            </Tabs>
            <div className="content">
                {activeTab === 'monitoring' && <Monitoring historyData={historyData} />}
                {activeTab === 'history' && <History historyData={historyData} />}
                {/* 他のタブのコンテンツもここに追加 */}
            </div>
        </Paper>
    );
};

export default DashboardTabss;
