// DownloadButton.jsx
import React, { useState, useCallback } from 'react';
import { Box, Fab } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadSuccessSnackbar from './DownloadSuccessSnackbar';
import DownloadErrorSnackbar from './DownloadErrorSnackbar';

const DownloadButton = ({ gridRef }) => {
    const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false); // ダウンロード成功Snackbarの表示状態の状態管理
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false); // ダウンロードエラーSnackbarの表示状態の状態管理

    // ダウンロードボタンクリックを処理するコールバック
    const handleDownloadButtonClick = useCallback(() => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.exportDataAsCsv();
            setOpenSuccessSnackbar(true);
        } else {
            setOpenErrorSnackbar(true);
        }
    }, []); 

    return (
        <Box>
            {/* ダウンロードボタン */}
            <Fab
                variant="extended"
                onClick={handleDownloadButtonClick}
                sx={{height: '40px'}}
            >
                <DownloadIcon sx={{ mr: 1 }}/>
                ダウンロード
            </Fab>
            {/* ダウンロード成功Snackbar */}
            <DownloadSuccessSnackbar 
                open={openSuccessSnackbar}
                onClose={() => setOpenSuccessSnackbar(false)}
            />
            {/* ダウンロードエラーSnackbar */}
            <DownloadErrorSnackbar 
                open={openErrorSnackbar}
                onClose={() => setOpenErrorSnackbar(false)}
            />
        </Box>
    );
};

export default DownloadButton;
