// History.jsx
import React, { useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import useResizeGrid from 'hooks/useResizeGrid';
import { formatPowerValue } from 'providers/FunctionProvider.jsx';

const History = ({ historyData }) => {
    const columnDefs = useMemo(() => [
        { headerName: "更新日時", field: "timestamp"},
        { headerName: "平均電力[W]", field: "powerTyp", valueFormatter: (params) => formatPowerValue(params.value)},
        { headerName: "最小電力[W]", field: "powerMin", valueFormatter: (params) => formatPowerValue(params.value)},
        { headerName: "最大電力[W]", field: "powerMax", valueFormatter: (params) => formatPowerValue(params.value)},
        { headerName: "測定間隔[秒]", field: "interval"},
    ], []);
    
    const gridRef = useRef(null);

    // グリッドの列の幅を自動調整するカスタムフックを使用
    useResizeGrid(gridRef);
    const { onGridReady } = useResizeGrid(gridRef);

    return (
        <div className="ag-theme-material" style={{ height: 400, width: '100%' }}>
            <AgGridReact
                ref={gridRef}
                rowData={historyData}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={20} 
                onGridReady={onGridReady}
            />
        </div>
    )
}

export default History;