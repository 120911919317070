// login.jsx
import React, { useState } from 'react';
import { signIn } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'providers/AuthProvider';
import { TextField, Button, Card, CardContent, Typography, Box, CircularProgress, Snackbar } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

const Login = () => {
    // 状態変数の定義
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordValidating, setIsPasswordValidating] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const {login} = useAuth(); // 認証コンテキストからlogin関数を取得
    const navigate = useNavigate(); // react-router-domのnavigate関数を取得
    
    // FormのSubmitイベントを処理する関数
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setIsPasswordValidating(true); // パスワード検証中を示すフラグをセット
            const { isSignedIn, nextStep } = await signIn({ username, password }); // ユーザー名とパスワードを使用してログインを試行
            if (isSignedIn) {
                login(); // ログイン成功時に認証情報を更新
                navigate('/'); // ログイン成功時にホームページにリダイレクト
            } else {
                if (nextStep && nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
                    navigate('/update-password'); // 初期パスワードが未変更の場合に、パスワード変更ページにリダイレクト
                }
                else {
                    console.log(nextStep);
                }
            }
        } catch (error) {
            if (!error.name) {
                // 予期せぬエラー
                setErrorMessage('ログイン中にエラーが発生しました。詳細はコンソールを確認してください。');
            } else {
                switch (error.name) {
                    case 'EmptySignInUsername':
                        setErrorMessage('ユーザー名が空です');
                        break;
                    case 'EmptySignInPassword':
                        setErrorMessage('パスワードが空です');
                        break;
                    case 'NotAuthorizedException':
                        setErrorMessage('ユーザ名またはパスワードを間違えています');
                        break;
                    case 'UserAlreadyAuthenticatedException':
                        setErrorMessage('既にログインしています。ログアウトしてから再試行してください。');
                        break;
                    default:
                        console.log(error);
                        break;
                }
            }
        } finally {
            setIsPasswordValidating(false); //検証が完了したらフラグをリセット
        }
    }

    // パスワードを忘れた場合の処理
    const handleForgetPassword = () => {
        navigate('/reset-password'); // パスワードリセットページにリダイレクト
    }

    return (
        <div>
            {/* ログインフォームのカード */}
            <Card style={{ maxWidth: 400, margin: '20px auto' }}>
                <CardContent>
                    {/* ロックアイコンとタイトル */}
                    <Box display="flex" alignItems="center" marginBottom="20px">
                        <LockIcon className="lockIcon" style={{marginRight: "8px"}}/>
                        <Typography variant='h5' component='h2'>
                            ログイン
                        </Typography>
                    </Box>
                    {/* ログインフォーム */}
                    <form onSubmit={handleSubmit} noValidate autoComplete="off">
                        {/* ユーザー名入力フィールド */}
                        <TextField
                            label="ユーザー名"
                            variant='outlined'
                            fullWidth
                            margin="normal"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            autoComplete="username"
                        />
                        {/* パスワード入力フィールド */}
                        <TextField
                            label='パスワード'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                            type='password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="current-password"
                        />
                        {/* ログインボタン */}
                        <Button
                            type='submit'
                            color='primary'
                            variant='contained'
                            fullWidth
                            style={{ marginTop: '20px', backgroundColor: isPasswordValidating ? '#a0a0a0' : '#1c2c56' }}
                            disabled={isPasswordValidating}
                        >
                            {isPasswordValidating ? (
                                <CircularProgress size={24} color='inherit' />
                            ) : (
                                'ログイン'
                            )}
                        </Button>
                        {/* パスワードを忘れた場合のリンク */}
                        <Button
                            color='primary'
                            fullWidth
                            onClick={handleForgetPassword}
                            style={{ marginTop: '20px'}}
                        >
                            パスワードを忘れた場合
                        </Button>
                    </form>
                </CardContent>
            </Card>
            {/* エラーメッセージの表示 */}
            <Snackbar
                open={errorMessage !== null}
                autoHideDuration={6000}
                onClose={() => setErrorMessage(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={errorMessage}
            />
        </div>
    );
};

export default Login;